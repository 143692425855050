/**
 * App Light Theme
 */
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#008080',
    },
    secondary: {
      main: '#EB7A21',
    },
    base: {
      main: '#ffffff',
    },
  },
  overrides: {
    MuiTableRow: {
      root: {
        height: 48,
      },
    },
  },
});

export default theme;
