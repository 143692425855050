/**
 * Content loader
 */
/* eslint-disable */
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const ContentLoader = ({ message }) => (
  <div className="iron-progress-bar d-flex justify-content-center align-items-center flex-col">
    <CircularProgress size={70} thickness={1.5} />

    <div className="mt-6">{message}</div>
  </div>
);

export default ContentLoader;
