/**
 * locales data
 */
export const languages = [
  {
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
];
