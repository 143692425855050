import AdminPanel from "../routes/admin-panel";

// routes

const AdminRoutes = [
   {
      path: 'admin',
      component: AdminPanel
   }
]

export default AdminRoutes;