/**
 * cart list item
 */
/* eslint-disable */
import React, { Fragment, useRef, useState } from 'react';
import Button from '@material-ui/core/Button';
import Popover from '@material-ui/core/Popover';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import { Link } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import ConfirmationDialog from '../../global/confirmation-popup';
import { formatMoney } from '../../../util/format';
import { removeItem } from '../../../store/cartSlice';

export default function Cart() {
  const [anchorEl, setAnchorEl] = useState();
  const confirmationDialog = useRef();
  const selectedCartItemId = useRef('');

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);

  //Define function for open dropdown
  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  //Define function for close dropdown
  function handleClose() {
    setAnchorEl(null);
  }

  //Function to delete product from cart
  function onDeleteCartItem(cartItem) {
    selectedCartItemId.current = cartItem.id;
    confirmationDialog.current.openDialog();
  }

  //Function for delete cart product
  function deleteCartItem(popupResponse) {
    if (popupResponse) {
      dispatch(removeItem(selectedCartItemId.current));
      selectedCartItemId.current = '';
    }
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <div className="iron-cart-wrap">
      <IconButton
        color="inherit"
        aria-owns={open ? 'simple-popper' : null}
        aria-haspopup="true"
        variant="contained"
        onClick={handleClick}
        className="icon-btn mr-10"
        aria-label="Cart"
      >
        {cart && cart.length > 0 ? (
          <Badge
            badgeContent={cart.length}
            color="secondary"
            className="badge-active"
          >
            <i className="material-icons">shopping_cart</i>
          </Badge>
        ) : (
          <i className="material-icons">shopping_cart</i>
        )}
      </IconButton>
      <Popover
        id="simple-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div>
          {cart && cart.length > 0 ? (
            <Fragment>
              <ul className="iron-cart-list-wrap mb-0">
                {cart &&
                  cart.map((cartItem, index) => {
                    return (
                      <li key={index} className="cart-menu-item p-10 mb-0">
                        <div className="d-flex iron-cart-post">
                          <div className="cart-thumb">
                            <img
                              src={cartItem.images?.[0]?.url}
                              alt="product-thumbnail"
                            />
                          </div>
                          <div className=" cart-content-wrap d-flex justify-content-start align-items-center">
                            <div className="cart-content">
                              <h6 className="mb-5 text-truncate">
                                {cartItem.title}
                              </h6>
                              <span>{formatMoney(cartItem.totalPrice)}</span>
                            </div>
                            <div className="cart-edit-action d-flex justify-content-end align-items-center">
                              <Button
                                className="icon-btn button mr-5"
                                onClick={() => onDeleteCartItem(cartItem)}
                              >
                                <i className="material-icons">
                                  remove_shopping_cart
                                </i>
                              </Button>
                              <Button
                                component={Link}
                                to="/cart"
                                className="icon-btn button"
                                onClick={handleClose}
                              >
                                <i className="material-icons">edit</i>
                              </Button>
                            </div>
                          </div>
                        </div>
                      </li>
                    );
                  })}
              </ul>
              <div className="py-15 px-10">
                <Button
                  onClick={handleClose}
                  component={Link}
                  to="/check-out"
                  className="button btn-active w-100"
                >
                  checkout
                </Button>
              </div>
            </Fragment>
          ) : (
            <div>
              <span className="text-capitalize text-14 dark-color d-block px-40 py-15">
                no product found
              </span>
            </div>
          )}
        </div>
        <ConfirmationDialog
          ref={confirmationDialog}
          onConfirm={(res) => deleteCartItem(res)}
        />
      </Popover>
    </div>
  );
}
