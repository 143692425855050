import React, { useReducer } from 'react';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Avatar from '@material-ui/core/Avatar';
import { Link, useHistory } from 'react-router-dom';
import { HiMiniUser } from 'react-icons/hi2';
import { useDispatch, useSelector } from 'react-redux';

import { logout } from '../../../store/userSlice';

export default function Logout() {
  const dispatch = useDispatch();
  const history = useHistory();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const [state, setState] = useReducer(
    (oldState, newState) => ({ ...oldState, ...newState }),
    {
      anchorEl: null,
      user: {
        url: null,
        alt: 'user',
      },
    }
  );

  const handleMenu = (event) => {
    setState({ anchorEl: event.currentTarget });
  };

  const handleClose = () => {
    setState({ anchorEl: null });
  };

  function logUserOut() {
    handleClose();
    dispatch(logout());
    history.push('/');
  }

  const loggedInUserMenu = [
    {
      id: 1,
      title: 'Profile',
      icon: 'account_circle',
      path: '/account/profile',
    },
    {
      id: 4,
      title: 'Logout',
      icon: 'power_settings_new',
      onClick: logUserOut,
    },
  ];

  const loggedOutUserMenu = [
    {
      id: 1,
      title: 'Login',
      icon: 'power_settings_new',
      path: '/sign-in',
    },
  ];

  const menus = isLoggedIn ? loggedInUserMenu : loggedOutUserMenu;

  const { anchorEl } = state;
  const open = Boolean(anchorEl);
  const user = state.user;

  return (
    <div className="iron-logout-wrap">
      <Avatar
        aria-owns={open ? 'menu-appbar' : null}
        aria-haspopup="true"
        onClick={handleMenu}
        className="icon-btn !bg-slate-400"
        alt={user.alt}
        // src={user.url}
      >
        <HiMiniUser size="30px" />
      </Avatar>
      <Menu
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={open}
        onClose={handleClose}
        className="iron-dropdown"
      >
        {menus.map((menu, index) => {
          const menuItemProps = menu.onClick
            ? {
                onClick: menu.onClick,
              }
            : { onClick: handleClose, to: menu.path, component: Link };
          return (
            <MenuItem
              key={index}
              {...menuItemProps}
              className="flex items-center"
            >
              <i className="material-icons w-[34px]">{menu.icon}</i>
              <span className="mb-0">{menu.title}</span>
            </MenuItem>
          );
        })}
      </Menu>
    </div>
  );
}
