import { createSlice } from '@reduxjs/toolkit';

const cartSlice = createSlice({
  name: 'cart',
  initialState: [],
  reducers: {
    addItem: (state, action) => {
      state.push({
        ...action.payload,
        totalPrice: action.payload.price,
        quantity: 1,
      });
    },
    updateQuantity: (state, action) => {
      const { newQuantity, productId } = action.payload;
      return state.map((item) =>
        item.id === productId
          ? {
              ...item,
              quantity: newQuantity,
              totalPrice: +item.price * newQuantity,
            }
          : item
      );
    },
    removeItem: (state, action) => {
      return state.filter((item) => item.id !== action.payload);
    },
    clearCart: () => {
      return [];
    },
  },
});

export const { addItem, removeItem, clearCart, updateQuantity } =
  cartSlice.actions;

export default cartSlice.reducer;
