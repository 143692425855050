/**
 * App Constants
 */
import logo from '../assets/images/header-logo.svg';

const AppConfig = {
  AppLogo: logo,
  rtlLayout: false, // RTL Layout
  adminLayout: false, // Admin Layout
  navCollapsed: false, // Sidebar Nav Layout
  algoliaConfig: {
    // Algolia configuration
    appId: 'latency',
    apiKey: '3d9875e51fbd20c7754e65422f7ce5e1',
    indexName: 'bestbuy',
  },
  // Default locale
  locale: {
    locale: 'en',
    name: 'English',
    icon: 'en',
  },
  // Footer about description
  AboutUs:
    'Ezzy Run is a tech startup leveraging AI-powered tools to optimize the grocery value chain. We deliver fresh groceries to our customers at the best price on the same day from the nearest open market.',
  // Copyright text
  CopyrightText: '© All Rights Reversed | Made With Love by Ezzy Run',
};

export default AppConfig;
