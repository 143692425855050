import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: {},
  token: {
    expiresIn: 0,
    accessToken: '',
  },
  isLoggedIn: false,
  inCheckoutProcess: false, // indicates that a user is in the process of checking out
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    login: (state, action) => {
      return {
        user: action.payload.user,
        token: action.payload.token,
        isLoggedIn: true,
      };
    },
    logout: (state, action) => {
      return initialState;
    },
    setInCheckoutProcess: (state, action) => {
      state.inCheckoutProcess = action.payload;
    },
  },
});

export const { login, logout, setInCheckoutProcess } = userSlice.actions;

export default userSlice.reducer;
