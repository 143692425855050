/**
 * currency data unicode
 */
export const currencies = [
  {
    name: 'NGN',
    // icon: require('../../assets/flag-icons/ng.svg').default,
    unicode: 'NGN',
  },
];

export const currencyUnicode = {
  AED: '\u062F\u002E\u0625',
  AFN: '\u060B',
  ALL: '\u004C\u0065\u006B',
  AMD: '\u058F',
  AOA: 'AOA',
  ARS: '\u0024',
  AUD: '\u0024',
  AWG: '\u0192',
  AZN: '\u20BC',
  BAM: '\u004B\u004D',
  BBD: '\u0024',
  BDT: '\u09F3',
  BGN: '\u043B\u0432',
  BHD: 'BHD',
  BIF: 'BIF',
  BMD: '\u0024',
  BND: '\u0024',
  BOB: '\u0024\u0062',
  BRL: '\u0052\u0024',
  BSD: '\u0024',
  BTN: 'BTN',
  BWP: '\u0050',
  BYN: '\u0042\u0072',
  BYR: '\u0070\u002E',
  BZD: '\u0024',
  CAD: '\u0024',
  CDF: 'CDF',
  CHF: '\u0043\u0048\u0046',
  CLF: 'CLF',
  CLP: '\u0024',
  CNY: '\u00A5',
  COP: '\u0024',
  CRC: '\u20A1',
  CUC: 'CUC',
  CUP: '\u20B1',
  CVE: 'CVE',
  CZK: '\u004b\u010d',
  DJF: 'DJF',
  DKK: '\u006b\u0072',
  DOP: '\u0052\u0044\u0024',
  DZD: 'DZD',
  EGP: '\u00A3',
  ERN: 'ERN',
  ETB: '\u0017',
  EUR: '\u20AC',
  FJD: '\u0024',
  FKP: '\u00A3',
  GBP: '\u00A3',
  GEL: '\u20BE',
  GHS: '\u00A2',
  GIP: '\u00A3',
  GMD: 'GMD',
  GNF: 'GNF',
  GTQ: '\u0051',
  GYD: '\u0024',
  HKD: '\u0024',
  HNL: '\u004C',
  HRK: '\u006B\u006E',
  HTG: 'HTG',
  HUF: '\u0046\u0074',
  IDR: '\u0052\u0070',
  ILR: 'ILR',
  ILS: '\u20AA',
  INR: '\u20B9',
  IQD: 'IQD',
  IRR: '\uFDFC',
  ISK: '\u006B\u0072',
  JMD: '\u004A\u0024',
  JOD: 'JOD',
  JPY: '\u00A5',
  KES: 'KES',
  KGS: '\u0043B\u00432',
  KHR: '\u17DB',
  KMF: 'KMF',
  KPW: '\u20A9',
  KRW: '\u20A9',
  KWD: 'KWD',
  KYD: '\u0024',
  KZT: '\u0043B\u00432',
  LAK: '\u20AD',
  LBP: '\u00A3',
  LKR: '\u20A8',
  LRD: '\u0024',
  LSL: 'LSL',
  LYD: 'LYD',
  MAD: '\u002E\u062F\u002E\u0645',
  MDL: 'MDL',
  MGA: 'MGA',
  MKD: '\u0434\u0435\u043D',
  MMK: '\u004B',
  MNT: '\u20AE',
  MOP: 'MOP',
  MRO: 'MRO',
  MUR: '\u20A8',
  MVR: 'MVR',
  MWK: 'MWK',
  MXN: '\u0024',
  MXV: 'MXV',
  MYR: '\u0052\u004D',
  MZN: '\u004D\u0054',
  NAD: '\u0024',
  NGN: '\u20A6',
  NIO: '\u0043\u0024',
  NOK: '\u006b\u0072',
  NPR: '\u20A8',
  NZD: '\u0024',
  OMR: '\uFDFC',
  PAB: '\u0042\u002F\u002E',
  PEN: '\u0053\u002F\u002E',
  PGK: 'PGK',
  PHP: '\u20B1',
  PKR: '\u20A8',
  PLN: '\u007A\u0142',
  PYG: '\u0047\u0073',
  QAR: '\uFDFC',
  RON: '\u006C\u0065\u0069',
  RSD: '\u0414\u0438\u043D\u002E',
  RUB: '\u0440\u0443\u0431',
  RWF: 'RWF',
  SAR: '\uFDFC',
  SBD: '\u0024',
  SCR: '\u20A8',
  SDG: '\u00A3',
  SEK: '\u006B\u0072',
  SGD: '\u0024',
  SHP: '\u00A3',
  SLL: 'SLL',
  SOS: '\u0053',
  SRD: '\u0024',
  SSP: '\u00A3',
  STD: 'STD',
  SVC: '\u0024',
  SYP: '\u00A3',
  SZL: 'SZL',
  THB: '\u0E3F',
  TJS: 'TJS',
  TMT: 'TMT',
  TND: '\u0044\u0054',
  TOP: 'TOP',
  TRY: '\u0054\u004C',
  TTD: '\u0024',
  TWD: '\u004E\u0054\u0024',
  TZS: 'TZS',
  UAH: '\u20B4',
  UGX: 'UGX',
  USD: '\u0024',
  UYI: 'UYI',
  UYU: '\u0024\u0055',
  UZS: '\u0043B\u00432',
  VEF: '\u0042\u0073',
  VND: '\u20AB',
  VUV: 'VUV',
  WST: 'WST',
  XAF: '\u0046\u0043\u0046\u0041',
  XCD: '\u0024',
  XOF: 'XOF',
  XPF: '\u0046',
  XXX: 'XXX',
  YER: '\uFDFC',
  ZAR: '\u0052',
  ZMW: 'ZMW',
  ZWL: '\u0024',
};
