/**
 * footer menu data
 */
const footerData = {
  about: [
    // {
    //   path: '/about-us',
    //   menu_title: 'footer.aboutUs',
    // },
    // {
    //   path: '/term-and-condition',
    //   menu_title: 'footer.termsAndCondition',
    // },
    // {
    //   path: '/privacy-policy',
    //   menu_title: 'footer.PrivacyPolicy',
    // },
    // {
    //   path: '/faq',
    //   menu_title: 'footer.Faq',
    // },
    {
      path: '/contact-us',
      menu_title: 'footer.contactUs',
    },
  ],
  categories: [
    {
      path: '/shop',
      menu_title: 'footer.women',
    },
    {
      path: '/shop',
      menu_title: 'footer.men',
    },
    {
      path: '/shop',
      menu_title: 'footer.accessories',
    },
    {
      path: '/shop',
      menu_title: 'footer.gadgets',
    },
  ],
  social: [
    {
      path: 'https://www.facebook.com',
      menu_title: 'footer.facebook',
    },
    {
      path: 'https://twitter.com',
      menu_title: 'footer.twitter',
    },
    {
      path: 'https://www.youtube.com',
      menu_title: 'footer.youtube',
    },
    {
      path: 'https://plus.google.com',
      menu_title: 'footer.googlePlus',
    },
  ],
};
export default footerData;
